import { jsx as r } from "react/jsx-runtime";
import { Checkbox as i } from "@mui/material";
import "../../../Chatbot.style-74668b14.js";
import "react";
import "../FormControl/FormControl.js";
import { FormControlLabel as e } from "../FormControlLabel/FormControlLabel.js";
import "../../../map-85279df3.js";
import "../../../server.browser-ec1ee1ca.js";
import "../Grid/Grid.js";
import "../Paper/Paper.js";
import "../Box/Box.js";
import "../Radio/Radio.js";
import "../RadioGroup/RadioGroup.js";
import "../Typography/Typography.js";
import "../Stepper/Stepper.js";
import "../Step/Step.js";
import "../StepLabel/StepLabel.js";
import "../../../style.module-3bbdaa05.js";
import "../../../DateTimeSelectorMenu-62cb07f8.js";
import "moment-timezone";
import "../../../runtime-6393d96e.js";
import "../../../TableContext-59b96d2b.js";
import "../../ReactTable/SearchFilter.js";
import "../../ReactTable/TablePaginationActions.js";
import "../../../styles/theme.js";
import "highcharts-react-official";
import "highcharts";
import "../../molecules/Widgets/WidgetTimelineGraph/WidgetTimelineGraph.styles.js";
import "../../../transform-17824b7f.js";
import "../../molecules/NetworkMap/NetworkMapUtils.js";
import "../../../ChatbotContext-74017a6b.js";
function I({ label: m, sx: o, ...p }) {
  return /* @__PURE__ */ r(
    e,
    {
      control: /* @__PURE__ */ r(
        i,
        {
          sx: [...Array.isArray(o) ? o : [o], t.checkboxStyles],
          ...p
        }
      ),
      label: m,
      sx: t.labelStyles
    }
  );
}
const t = {
  checkboxStyles: {
    p: 0
  },
  labelStyles: {
    gap: "6px",
    marginRight: "0px"
  }
};
export {
  I as Checkbox
};
