import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {userService} from "~/services";
import {UserInfo} from "~/types/user";
import {RootState} from "..";

type initState = {
  open: boolean;
  current: UserInfo | null;
  data: UserInfo[];
}

const initialState: initState = {
  open: false,
  current: null,
  data: [],
}

export const fetchUsers = createAsyncThunk("user/fetchUsers", async () => {
  return await userService.getAllUser();
})

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setValues: (state, action: PayloadAction<UserInfo | null>) => {
      state.current = action.payload;
    },
   
    setData: (state, action: PayloadAction<UserInfo[]>) => {
      state.data = action.payload;

      const current = action.payload.find((item) => item.id === state.current?.id);
      if (current) {
        state.current = current;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.data = action.payload;

      // Sync current with latest data
      if (state.current && !state.open) {
        const current = action.payload.find((item) => item.id === state.current?.id);
        if (current) {
          state.current = current;
        }
      }
    })
      .addCase(fetchUsers.rejected, (state, action) => {
        console.log(action.error.message);
        state.data = [];
      })
  }
});

export const userActions = userSlice.actions;

export default userSlice.reducer;

export const userData = (state: RootState) => state.user.data;
export const userState = (state: RootState) => state.user.current;

