import { jsx as i, jsxs as e } from "react/jsx-runtime";
import { FormControl as A, Select as S, MenuItem as s } from "@mui/material";
import { Grid as B } from "../Grid/Grid.js";
import { Typography as h } from "../Typography/Typography.js";
import { d as C } from "../../../KeyboardArrowDown-11e4031f.js";
import { styles as x } from "./Select.styles.js";
import "../../../Chatbot.style-74668b14.js";
import "react";
import { Checkbox as E } from "../Checkbox/Checkbox.js";
import { Divider as j } from "../Divider/Divider.js";
import "../FormControl/FormControl.js";
import "../FormControlLabel/FormControlLabel.js";
import "../../../map-85279df3.js";
import "../../../server.browser-ec1ee1ca.js";
import "../Paper/Paper.js";
import { Box as k } from "../Box/Box.js";
import "../Radio/Radio.js";
import "../RadioGroup/RadioGroup.js";
import "../Stepper/Stepper.js";
import "../Step/Step.js";
import "../StepLabel/StepLabel.js";
import "../../../style.module-3bbdaa05.js";
import "../../../DateTimeSelectorMenu-62cb07f8.js";
import "moment-timezone";
import "../../../runtime-6393d96e.js";
import "../../../TableContext-59b96d2b.js";
import "../../ReactTable/SearchFilter.js";
import "../../ReactTable/TablePaginationActions.js";
import "../../../styles/theme.js";
import "highcharts-react-official";
import "highcharts";
import "../../molecules/Widgets/WidgetTimelineGraph/WidgetTimelineGraph.styles.js";
import "../../../transform-17824b7f.js";
import "../../molecules/NetworkMap/NetworkMapUtils.js";
import "../../../ChatbotContext-74017a6b.js";
function lr({
  id: l,
  DropdownIcon: n,
  options: p,
  placeholder: u,
  value: o,
  variant: y,
  sx: a,
  customFooter: d,
  emptyStateText: b = "No options available",
  ...g
}) {
  return /* @__PURE__ */ i(A, { variant: y, hiddenLabel: !0, children: /* @__PURE__ */ e(
    S,
    {
      id: l,
      labelId: `${l}-label`,
      displayEmpty: !0,
      IconComponent: (r) => n ? /* @__PURE__ */ i(n, { ...r }) : /* @__PURE__ */ i(C, { ...r }),
      sx: [x.common, ...Array.isArray(a) ? a : [a]],
      renderValue: (r) => {
        const c = Array.isArray(r);
        if (!r || c && r.length === 0)
          return u;
        if (c)
          return p.filter((m) => r.includes(m.value)).map((m) => m == null ? void 0 : m.label).join(", ");
        const t = p.find((f) => f.value === r);
        return /* @__PURE__ */ e(B, { container: !0, sx: x.selectedContainer, children: [
          t == null ? void 0 : t.icon,
          t == null ? void 0 : t.label
        ] });
      },
      value: o,
      ...g,
      children: [
        p.length === 0 ? /* @__PURE__ */ i(s, { disabled: !0, sx: { opacity: "1 !important" }, children: /* @__PURE__ */ i(h, { sx: { fontSize: "12px", fontWeight: 400, color: "#878787" }, children: b }) }) : p.map((r) => /* @__PURE__ */ e(
          s,
          {
            value: r.value,
            sx: { display: "flex", gap: 1 },
            disabled: r.disabled,
            children: [
              Array.isArray(o) && /* @__PURE__ */ i(E, { checked: o == null ? void 0 : o.includes(r.value) }),
              r.icon,
              /* @__PURE__ */ i(h, { sx: { fontSize: "12px", fontWeight: 400 }, children: r.label })
            ]
          },
          r.id
        )),
        d && /* @__PURE__ */ e(k, { children: [
          /* @__PURE__ */ i(j, { sx: { marginTop: "8px", marginBottom: "8px", borderColor: "#EBEBEB" } }),
          d
        ] })
      ]
    }
  ) });
}
export {
  lr as Select
};
