import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { CARRIER_MODE, CPI_TYPE, IPSEC_MODE } from "../../constants";
import { UngroupedRadioID } from "../../constants/types";
import { geoDistance } from "../../helpers/geoDistance";
import { radioService } from "../../services";
import { ObjectReference } from "../../types/dataObject";
import Radio, { CellParameter, defaultRadio, EdgeReference, LocalExit } from "../../types/radio";
import { RadioFormat } from "../../views/radios/Import/Sign";
import { decodeJWTToken } from "../../views/radios/Import/Utils";

type initState = {
  editState: Radio;
  openSerialNumber: boolean;
  radioGrouping: boolean;
  showAdvanceEdges: boolean;
  showAdvanceFrequency: boolean;
  iAgree: boolean;
  cpiSignValid: boolean;
  cpiSignError: string[][]
  expanded: boolean;
  powerRange: { start: number, end: number };
  supportedCarrierModes: string[];
};

const initialState: initState = {
  editState: defaultRadio,
  openSerialNumber: false,
  radioGrouping: false,
  showAdvanceEdges: false,
  showAdvanceFrequency: false,
  iAgree: false,
  cpiSignValid: false,
  cpiSignError: [],
  expanded: false,
  powerRange: { start: 17, end: 30 },
  supportedCarrierModes: [
    CARRIER_MODE.SINGLE_CARRIER,
    CARRIER_MODE.DUAL_CARRIER,
    CARRIER_MODE.CARRIER_AGGREGATION,
  ],
};

type EdgeRef = {
  id: string;
  name: string;
  type: string;
};

const radioEditSlice = createSlice({
  name: "radioEdit",
  initialState,
  reducers: {
    setRadioEdit: (state, action: PayloadAction<Radio>) => {
      state.editState = action.payload;

      // if radio grouping based on radio group
      const rg = action.payload.radioGroup;
      state.radioGrouping = rg && rg.id !== UngroupedRadioID ? true : false;

      // set CPI sign valid
      // if carrierMode is single carrier, check one cell sign
      // else check all cell sign
      if (action.payload.carrierMode === CARRIER_MODE.SINGLE_CARRIER) {
        state.cpiSignValid = !!action.payload.cellParameters?.[0]?.signature;
        state.iAgree = !!action.payload.cellParameters?.[0]?.signature;
      } else {
        state.cpiSignValid = action.payload.cellParameters?.every((cell) => !!cell.signature) || false;
        state.iAgree = action.payload.cellParameters?.every((cell) => !!cell.signature) || false;
      }

      // if override edges is enabled, set expanded to true
      if (action.payload.overrideEdges || !state.radioGrouping) {
        state.expanded = true;
      }
    },
    setOpenSerialNumber: (state, action: PayloadAction<boolean>) => {
      state.openSerialNumber = action.payload;
    },
    setGrouping: (state, action: PayloadAction<boolean>) => {
      state.radioGrouping = action.payload;
    },
    setShowAdvanceEdges: (state, action: PayloadAction<boolean>) => {
      state.showAdvanceEdges = action.payload;
    },
    setRebootIfRequired: (state, action: PayloadAction<boolean>) => {
      state.editState.rebootIfRequired = action.payload;
    },
    setShowAdvanceFrequency: (state, action: PayloadAction<boolean>) => {
      state.showAdvanceFrequency = action.payload;
    },

    setExpanded: (state, action: PayloadAction<boolean>) => {
      state.expanded = action.payload;
    },

    setPowerRange: (state, action: PayloadAction<{start : number, end : number}>) => {
      state.powerRange = action.payload;
    },

    setSupportedCarrierModes: (state, action: PayloadAction<string[]>) => {
      state.supportedCarrierModes = action.payload;
    },

    // General
    setName: (state, action: PayloadAction<string>) => {
      state.editState.name = action.payload;
    },
    setSerialNumber: (state, action: PayloadAction<string>) => {
      state.editState.serialNumber = action.payload;
      if (action.payload === "") {
        state.editState.isAddedToRadioManager = false;
      }
    },
    setEdge: (state, action: PayloadAction<{ value: EdgeRef | EdgeReference[]; index: number }>) => {
      if (state.editState.edges && action.payload.index !== -1)
        state.editState.edges[action.payload.index].edge = action.payload.value as EdgeRef;
      if (action.payload.index === -1) state.editState.edges = action.payload.value as EdgeReference[];
    },
    removeEdge: (state, action: PayloadAction<number>) => {
      if (state.editState.edges) state.editState.edges = state.editState.edges.filter((_, i) => i !== action.payload);
    },
    addNewEdge: (state) => {
      if (state.editState.edges) state.editState.edges.push({ edge: { id: "" }, ipsecMode: IPSEC_MODE.PSK });
    },
    setIPsecMode: (state, action: PayloadAction<{ value: string; index: number }>) => {
      if (state.editState.edges) state.editState.edges[action.payload.index].ipsecMode = action.payload.value;
    },
    setRadioSite: (state, action: PayloadAction<ObjectReference | undefined>) => {
      state.editState.radioSite = action.payload;
    },
    setRadioGroup: (state, action: PayloadAction<ObjectReference | undefined>) => {
      state.editState.radioGroup = action.payload;
    },
    setOverrideEdges: (state, action: PayloadAction<boolean>) => {
      state.editState.overrideEdges = action.payload;
    },

    setSimulatedAppliance: (state, action: PayloadAction<ObjectReference | undefined>) => {
      state.editState.simulatedAppliance = action.payload;
    },
    setNeighborsAutoAssign: (state, action: PayloadAction<boolean>) => {
      state.editState.neighborsAutoAssign = action.payload;
    },
    setBorderRadio: (state, action: PayloadAction<boolean>) => {
      state.editState.isBorderRadio = action.payload;
    },
    setTechnology: (state, action: PayloadAction<string>) => {
      state.editState.technology = action.payload;
    },

    setCablingInfo: (state, action: PayloadAction<string>) => {
      if(state.editState.installInfo){
        state.editState.installInfo.cablingInfo = action.payload;
      } else {
        state.editState.installInfo = {cablingInfo: action.payload}
      }
    },

    // location
    setLocationSyncMode: (state, action: PayloadAction<string>) => {
      state.editState.locationSyncMode = action.payload;
    },
    setSyncProfile: (state, action: PayloadAction<ObjectReference | undefined>) => {
      state.editState.synchronizationProfile = action.payload;
    },
    setLatitude: (state, action: PayloadAction<number>) => {
      state.editState.location = {
        ...state.editState.location,
        latitude: action.payload,
      };
    },
    setLongitude: (state, action: PayloadAction<number>) => {
      state.editState.location = {
        ...state.editState.location,
        longitude: action.payload,
      };
    },
    setLocationName: (state, action: PayloadAction<string>) => {
      state.editState.location = {
        ...state.editState.location,
        name: action.payload,
      };
    },
    setZoom: (state, action: PayloadAction<number>) => {
      state.editState.location = {
        ...state.editState.location,
        zoom: action.payload,
      };
    },
    setLocationType: (state, action: PayloadAction<string>) => {
      state.editState.location = {
        ...state.editState.location,
        locationType: action.payload,
      };
    },
    setMapType: (state, action: PayloadAction<string>) => {
      state.editState.location = {
        ...state.editState.location,
        view: action.payload.toUpperCase(),
      };
    },

    setFloorCoods: (state, action: PayloadAction<number[]>) => {
      state.editState.floorPlan = {
        xOffset: action.payload[0],
        yOffset: action.payload[1],
      };
    },

    // cell and frequency
    setCellParameter: (state, action: PayloadAction<CellParameter[]>) => {
      state.editState.cellParameters = action.payload;
    },
    setCell0: (state, action: PayloadAction<CellParameter>) => {
      if (state.editState.cellParameters) {
        state.editState.cellParameters[0] = action.payload;
      } else {
        state.editState.cellParameters = [action.payload];
      }
    },
    setCell1: (state, action: PayloadAction<CellParameter>) => {
      if (state.editState.cellParameters) {
        state.editState.cellParameters[1] = action.payload;
      } else {
        state.editState.cellParameters = [action.payload];
      }
    },

    setCarrierMode: (state, action: PayloadAction<string>) => {
      state.editState.carrierMode = action.payload;
    },
    setCarrier: (state, action: PayloadAction<{ value: ObjectReference[]; index: number }>) => {
      if (state.editState.edges) state.editState.edges[action.payload.index].carriers = action.payload.value;
    },
    setTac: (state, action: PayloadAction<{ tac: number; cellIndex?: number }>) => {
      if (state.editState.cellParameters) {
        // state.editState.cellParameters[action.payload.cellIndex ?? 0].tac = action.payload.tac

        const cells = state.editState.cellParameters.map((cell, index) => {
          cell.tac = action.payload.tac;
          return cell;
        });
        state.editState.cellParameters = cells;
      }
    },

    setBand: (state, action: PayloadAction<{ band: string; cellIndex?: number }>) => {
      if (state.editState.cellParameters) {
        // state.editState.cellParameters[action.payload.cellIndex ?? 0].band = action.payload.band

        const cells = state.editState.cellParameters.map((cell, index) => {
          cell.band = action.payload.band;
          return cell;
        });
        state.editState.cellParameters = cells;
      }
    },

    setBandwidth: (state, action: PayloadAction<{ bandwidth: number; cellIndex?: number }>) => {
      if (state.editState.cellParameters) {
        state.editState.cellParameters[action.payload.cellIndex ?? 0].bandwidth = action.payload.bandwidth;
      }
    },

    setSubframeAssignment: (state, action: PayloadAction<{ subframeAssignment: number; cellIndex?: number }>) => {
      if (state.editState.cellParameters) {
        state.editState.cellParameters[action.payload.cellIndex ?? 0].subframeAssignment =
          action.payload.subframeAssignment;
      }
    },

    setSpecialSubframePattern: (
      state,
      action: PayloadAction<{ specialSubframePattern: number; cellIndex?: number }>
    ) => {
      if (state.editState.cellParameters) {
        state.editState.cellParameters[action.payload.cellIndex ?? 0].specialSubframePattern =
          action.payload.specialSubframePattern;
      }
    },

    setRFEnabled: (state, action: PayloadAction<{ rfEnabled: boolean; cellIndex?: number }>) => {
      if (state.editState.cellParameters) {
        state.editState.cellParameters[action.payload.cellIndex ?? 0].rfEnabled = action.payload.rfEnabled;
      }
    },

    setCellId: (state, action: PayloadAction<{ cellId: number; cellIndex?: number }>) => {
      if (state.editState.cellParameters) {
        state.editState.cellParameters[action.payload.cellIndex ?? 0].cellId = action.payload.cellId;
      }
    },

    setECIAutoAssign: (state, action: PayloadAction<{ eciAutoAssign: boolean; cellIndex?: number }>) => {
      if (state.editState.cellParameters) {
        state.editState.cellParameters[action.payload.cellIndex ?? 0].eciAutoAssign = action.payload.eciAutoAssign;
      }
    },
    setPCIAutoAssign: (state, action: PayloadAction<{ pciAutoAssign: boolean; cellIndex?: number }>) => {
      if (state.editState.cellParameters) {
        state.editState.cellParameters[action.payload.cellIndex ?? 0].pciAutoAssign = action.payload.pciAutoAssign;
      }
    },
    setEARFCAutoAssign: (state, action: PayloadAction<{ earfcnAutoAssign: boolean; cellIndex?: number }>) => {
      if (state.editState.cellParameters) {
        state.editState.cellParameters[action.payload.cellIndex ?? 0].earfcnAutoAssign =
          action.payload.earfcnAutoAssign;
      }
    },

    setOverrideBandwidth: (state, action: PayloadAction<{ overrideBandwidth: boolean; cellIndex?: number }>) => {
      if (state.editState.cellParameters) {
        state.editState.cellParameters[action.payload.cellIndex ?? 0].overrideBandwidth =
          action.payload.overrideBandwidth;
      }
    },

    setPCI: (state, action: PayloadAction<{ pci: number; cellIndex?: number }>) => {
      if (state.editState.cellParameters) {
        state.editState.cellParameters[action.payload.cellIndex ?? 0].pci = action.payload.pci;
      }
    },

    setPower: (state, action: PayloadAction<{ power: number; cellIndex?: number }>) => {
      if (state.editState.cellParameters) {
        state.editState.cellParameters[action.payload.cellIndex ?? 0].power = action.payload.power;
      }
    },

    setEarfcn: (state, action: PayloadAction<{ earfcn: number; cellIndex?: number }>) => {
      if (state.editState.cellParameters) {
        state.editState.cellParameters[action.payload.cellIndex ?? 0].earfcn = [action.payload.earfcn];
      }
    },

    // SAS
    setSasEnabled: (state, action: PayloadAction<boolean>) => {
      if (state.editState.sasConfig) {
        state.editState.sasConfig.sasEnabled = action.payload;
      } else {
        state.editState.sasConfig = { ...defaultRadio.sasConfig, sasEnabled: action.payload };
      }
    },
    setSasUserId: (state, action: PayloadAction<string>) => {
      if (state.editState.sasConfig) {
        state.editState.sasConfig.userId = action.payload;
      } else {
        state.editState.sasConfig = { ...defaultRadio.sasConfig, userId: action.payload };
      }
    },
    setOverrideSasConfig: (state, action: PayloadAction<boolean>) => {
      if (state.editState.sasConfig) {
        state.editState.sasConfig.overrideSasConfig = action.payload;
      } else {
        state.editState.sasConfig = { ...defaultRadio.sasConfig, overrideSasConfig: action.payload };
      }
    },
    setFccId: (state, action: PayloadAction<string>) => {
      if (state.editState.sasConfig) {
        state.editState.sasConfig.fccId = action.payload;
      } else {
        state.editState.sasConfig = { ...defaultRadio.sasConfig, fccId: action.payload };
      }
    },
    setModel: (state, action: PayloadAction<string>) => {
      state.editState.model = action.payload;
    },
    setCbsdCategory: (state, action: PayloadAction<string>) => {
      if (state.editState.sasConfig) {
        state.editState.sasConfig.cbsdCategory = action.payload;
      } else {
        state.editState.sasConfig = { ...defaultRadio.sasConfig, cbsdCategory: action.payload };
      }
    },
    setFrequencySelectionLogic: (state, action: PayloadAction<string>) => {
      if (state.editState.sasConfig) {
        state.editState.sasConfig.frequencySelectionLogic = action.payload;
      } else {
        state.editState.sasConfig = { ...defaultRadio.sasConfig, frequencySelectionLogic: action.payload };
      }
    },
    setIsOnlyAllowSelectedFrequency: (state, action: PayloadAction<boolean>) => {
      if (state.editState.sasConfig) {
        state.editState.sasConfig.isOnlyAllowSelectedFrequency = action.payload;
      } else {
        state.editState.sasConfig = { ...defaultRadio.sasConfig, isOnlyAllowSelectedFrequency: action.payload };
      }
    },
    setCpiType: (state, action: PayloadAction<string>) => {
      if (state.editState.sasConfig?.cpiData) {
        state.editState.sasConfig.cpiData.cpiType = action.payload;
      } else {
        state.editState.sasConfig = {
          ...defaultRadio.sasConfig,
          ...state.editState.sasConfig,
          cpiData: {
            cpiType: action.payload,
          },
        };
      }
    },
    updateCpiData: (
      state,
      action: {
        payload: {
          id: string;
          name: string;
        };
      }
    ) => {
      if (state.editState.sasConfig?.cpiData) {
        state.editState.sasConfig.cpiData = {
          ...state.editState.sasConfig.cpiData,
          ...action.payload,
          cpiType: state.editState.sasConfig.cpiData.cpiType ?? CPI_TYPE.THIRD_PARTY,
        };
        // if sas config does not exist, create it with default values
      } else {
        state.editState.sasConfig
          ? (state.editState.sasConfig.cpiData = {
            ...defaultRadio.sasConfig?.cpiData,
            ...action.payload,
          })
          : (state.editState.sasConfig = {
            ...defaultRadio.sasConfig,
            cpiData: { ...defaultRadio.sasConfig?.cpiData, ...action.payload },
          });
      }
    },

    setBeamwidth: (state, action: PayloadAction<{ beamwidth: number; cellIndex?: number }>) => {
      if (state.editState.cellParameters) {
        state.editState.cellParameters[action.payload.cellIndex ?? 0].beamwidth = action.payload.beamwidth;
        syncCellParameters(state);
      }
    },

    setGain: (state, action: PayloadAction<{ gain: number; cellIndex?: number }>) => {
      if (state.editState.cellParameters) {
        state.editState.cellParameters[action.payload.cellIndex ?? 0].gain = action.payload.gain;
        syncCellParameters(state);
      }
    },

    setAzimuth: (state, action: PayloadAction<{ azimuth: number; cellIndex?: number }>) => {
      if (state.editState.cellParameters) {
        state.editState.cellParameters[action.payload.cellIndex ?? 0].azimuth = action.payload.azimuth;
        syncCellParameters(state);
      }
    },

    setDownTilt: (state, action: PayloadAction<{ downTilt: number; cellIndex?: number }>) => {
      if (state.editState.cellParameters) {
        state.editState.cellParameters[action.payload.cellIndex ?? 0].downTilt = action.payload.downTilt;
        syncCellParameters(state);
      }
    },

    setAltitude: (state, action: PayloadAction<{ altitude: number; cellIndex?: number }>) => {
      if (state.editState.cellParameters) {
        state.editState.cellParameters[action.payload.cellIndex ?? 0].altitude = action.payload.altitude;
        syncCellParameters(state);
      }
    },

    setIAgree: (state, action: PayloadAction<boolean>) => {
      state.iAgree = action.payload;
    },

    setCPISignValid: (state, action: PayloadAction<boolean>) => {
      state.cpiSignValid = action.payload;
    },

    setCPISignError: (state, action: PayloadAction<string[][]>) => {
      state.cpiSignError = action.payload;
    },

    // MOBILITY
    setNeighborList: (state, action: PayloadAction<any>) => {
      state.editState.neighborList = action.payload;
    },

    // advance

    setLocalExit: (state, action: PayloadAction<LocalExit>) => {
      state.editState.localExit = action.payload;
    },

    setLocalExitEnabled: (state, action: PayloadAction<boolean>) => {
      if (state.editState.localExit) {
        state.editState.localExit.enabled = action.payload;
      } else {
        state.editState.localExit = { ...defaultRadio.localExit, enabled: action.payload };
      }
    },

    setLocalExitMode: (state, action: PayloadAction<number>) => {
      if (state.editState.localExit) {
        state.editState.localExit.mode = action.payload;
      } else {
        state.editState.localExit = { ...defaultRadio.localExit, mode: action.payload };
      }
    },

    setLocalExitIPPool: (state, action: PayloadAction<string>) => {
      if (state.editState.localExit) {
        state.editState.localExit.ipPool = action.payload;
      } else {
        state.editState.localExit = { ...defaultRadio.localExit, ipPool: action.payload };
      }
    },

    setLocalExitIPPoolNetmask: (state, action: PayloadAction<string>) => {
      if (state.editState.localExit) {
        state.editState.localExit.ipPoolNetmask = action.payload;
      } else {
        state.editState.localExit = { ...defaultRadio.localExit, ipPoolNetmask: action.payload };
      }
    },

    setLocalExitEnableStaticIp: (state, action: PayloadAction<boolean>) => {
      if (state.editState.localExit) {
        state.editState.localExit.enableStaticIp = action.payload;
      } else {
        state.editState.localExit = { ...defaultRadio.localExit, enableStaticIp: action.payload };
      }
    },

    setLocalExitStaticIpStart: (state, action: PayloadAction<string>) => {
      if (state.editState.localExit) {
        state.editState.localExit.staticIpStart = action.payload;
      } else {
        state.editState.localExit = { ...defaultRadio.localExit, staticIpStart: action.payload };
      }
    },

    setLocalExitStaticIpEnd: (state, action: PayloadAction<string>) => {
      if (state.editState.localExit) {
        state.editState.localExit.staticIpEnd = action.payload;
      } else {
        state.editState.localExit = { ...defaultRadio.localExit, staticIpEnd: action.payload };
      }
    },

    setIsManaged: (state, action: PayloadAction<boolean>) => {
      state.editState.isManaged = action.payload;
    },

    setIsPartnerRadio: (state, action: PayloadAction<boolean>) => {
      state.editState.isPartnerRadio = action.payload;
    },

    setModelType: (state, action: PayloadAction<string>) => {
      state.editState.modelType = action.payload;
    },
    setVendorType: (state, action: PayloadAction<string>) => {
      state.editState.vendorType = action.payload;
    }
  },
});

export const radioEditActions = radioEditSlice.actions;
export default radioEditSlice.reducer;

export const RadioEditState = (state: RootState) => state.radioEdit.editState;
export const RadioEditOpenSerialNumber = (state: RootState) => state.radioEdit.openSerialNumber;
export const RadioEditGrouping = (state: RootState) => state.radioEdit.radioGrouping;
export const RadioEditShowAdvanceEdges = (state: RootState) => state.radioEdit.showAdvanceEdges;
export const RadioEditRebootIfRequired = (state: RootState) => state.radioEdit.editState.rebootIfRequired;
export const RadioEditShowAdvanceFrequency = (state: RootState) => state.radioEdit.showAdvanceFrequency;

export const RadioEditIagree = (state: RootState) => state.radioEdit.iAgree;
export const RadioEditCpiSignValid = (state: RootState) => state.radioEdit.cpiSignValid;
export const RadioEditCpiSignError = (state: RootState) => state.radioEdit.cpiSignError;

export const RadioEditExpanded = (state: RootState) => state.radioEdit.expanded;
export const RadioEditSupportedCarrierModes = (state: RootState) => state.radioEdit.supportedCarrierModes;
export const RadioEditFccId = (state: RootState) => state.radioEdit.editState.sasConfig?.fccId;
export const RadioEditModelType = (state: RootState) => state.radioEdit.editState.modelType;
export const RadioEditVendorType = (state: RootState) => state.radioEdit.editState.vendorType;

export function generateRadioFormat(radio: Radio, i = 0) {
  let cbsdSerialNumber = radio.serialNumber!;
  // if carrier mode is dual
  if (i > 0 && radio.carrierMode === CARRIER_MODE.DUAL_CARRIER) {
    cbsdSerialNumber = radio.serialNumber! + "-" + (i + 1);
  }

  if(i > 0) {
    if(radio.carrierMode === CARRIER_MODE.DUAL_CARRIER) {
      cbsdSerialNumber = radio.serialNumber! + "-" + (i + 1);
    } else if (radio.modelType === 'Outdoor' && radio.vendorType === "AIRSPAN") {
      cbsdSerialNumber = radio.serialNumber! + ":" + (i + 1);
    }
  }

  const Cells = radio.cellParameters?.map((cell, index) => {
    return {
      antennaBeamwidth: cell.beamwidth,
      antennaGain: cell.gain,
      antennaDowntilt: cell.downTilt,
      antennaAzimuth: cell.azimuth,
      height: cell.altitude!,
    };
  })!;

  const _radio = {
    name: radio.name,
    cpiType: radio.sasConfig?.cpiData?.cpiType ?? CPI_TYPE.THIRD_PARTY,
    carrierMode: radio.carrierMode!,
    cellParameters: Cells,
    longitude: radio.location?.longitude!,
    latitude: radio.location?.latitude!,
    fccId: radio.sasConfig?.fccId!,
    cbsdSerialNumber,
    indoorDeployment: radio.sasConfig?.cbsdCategory === "A" ? true : false,
    cpiId: radio.sasConfig?.cpiData?.id!,
    cpiName: radio.sasConfig?.cpiData?.name!,
  };
  return _radio;
}
export function validateSignature(signature: string, radio: Radio, i = 0) {
  if (!signature) return {
    reasons: [['Signature is empty']],
    isValid: false
  }

  const _radio = generateRadioFormat(radio, i);

  const decoded = decodeJWTToken(signature)!;
  // console.log('decode sign', decoded)
  // let _user = user as UserInfo
  const ValidationParams = {
    CBSDSerial: { decoded: decoded.cbsdSerialNumber, expected: _radio.cbsdSerialNumber },
    FCCID: { decoded: decoded.fccId, expected: _radio.fccId },

    AntennaAzimuth: {
      decoded: decoded.installationParam.antennaAzimuth,
      expected: _radio.cellParameters[i].antennaAzimuth,
    },
    AntennaBeamwidth: {
      decoded: decoded.installationParam.antennaBeamwidth,
      expected: _radio.cellParameters[i].antennaBeamwidth,
    },
    AntennaDowntilt: {
      decoded: decoded.installationParam.antennaDowntilt,
      expected: _radio.cellParameters[i].antennaDowntilt,
    },
    AntennaGain: { decoded: decoded.installationParam.antennaGain, expected: _radio.cellParameters[i].antennaGain },
    Height: { decoded: decoded.installationParam.height, expected: _radio.cellParameters[i].height },

    GeoDistance: {
      decoded: geoDistance(
        decoded.installationParam.latitude,
        decoded.installationParam.longitude,
        _radio.latitude,
        _radio.longitude
      ),
      expected: 100,
    },
    IndoorDeployment: { decoded: decoded.installationParam.indoorDeployment, expected: _radio.indoorDeployment },
    CPIID: { decoded: decoded.professionalInstallerData.cpiId, expected: _radio.cpiId },
    CPIName: { decoded: decoded.professionalInstallerData.cpiName, expected: _radio.cpiName },
  };

  const ValidationCriteria: { [key: string]: boolean } = {};
  Object.keys(ValidationParams).forEach((x) => {
    const key = x as keyof typeof ValidationParams;

    if (key === "GeoDistance") {
      ValidationCriteria[key] = ValidationParams[key].decoded <= ValidationParams[key].expected;
    } else {
      ValidationCriteria[key] = ValidationParams[key].decoded === ValidationParams[key].expected;
    }
  });

  // console.log('ddd will validate', ValidationCriteria)

  const isValid = Object.values(ValidationCriteria).every((v) => v === true);
  const reasons: string[][] = Array.from({ length: i + 1 }, () => []);
  // give the invalid reason
  if (!isValid) {
    const invalidReason = Object.keys(ValidationCriteria).filter((key) => ValidationCriteria[key] === false);
    invalidReason.forEach((key) => {
      console.info("invalid reason", key, ValidationParams[key as keyof typeof ValidationParams]);
      if (key === "GeoDistance") {
        reasons[i]?.push(
          `Radio is located too far from the location specified when signing. Distance from the location in the signature is ${Number(
            ValidationParams[key as keyof typeof ValidationParams].decoded
          ).toFixed(2)} meters.`
        );
      } else {
        reasons[i]?.push(
          `${key} - Value in signature: ${ValidationParams[key as keyof typeof ValidationParams].decoded}, Configured value: ${ValidationParams[key as keyof typeof ValidationParams].expected}`
        );
      }
    });
  }

  return {
    isValid,
    reasons,
  };
}

export async function requestSignature(radioData: RadioFormat) {
  try {
    const response = await radioService.signCpi(radioData, "");
    const JsonArray = await response.text();
    const result = JSON.parse(JsonArray).data;

    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

function syncCellParameters(state: any) {
  if (state.editState.cellParameters && (state.editState.carrierMode === CARRIER_MODE.CARRIER_AGGREGATION && (state.editState.modelType !== 'Outdoor' && state.editState.vendorType !== "AIRSPAN") )) {
    state.editState.cellParameters[1].altitude = state.editState.cellParameters[0].altitude;
    state.editState.cellParameters[1].azimuth = state.editState.cellParameters[0].azimuth;
    state.editState.cellParameters[1].beamwidth = state.editState.cellParameters[0].beamwidth;
    state.editState.cellParameters[1].downTilt = state.editState.cellParameters[0].downTilt;
    state.editState.cellParameters[1].gain = state.editState.cellParameters[0].gain;
  }
}
