import { jsx as r, jsxs as i, Fragment as h } from "react/jsx-runtime";
import { CircularProgress as u, Menu as v, MenuItem as f } from "@mui/material";
import { useChatbotContext as M } from "./hooks/index.js";
import "../../Chatbot.style-74668b14.js";
import z from "react";
import "../atoms/FormControl/FormControl.js";
import "../atoms/FormControlLabel/FormControlLabel.js";
import "../../map-85279df3.js";
import "../../server.browser-ec1ee1ca.js";
import "../atoms/Grid/Grid.js";
import "../atoms/Paper/Paper.js";
import "../atoms/Box/Box.js";
import "../atoms/Radio/Radio.js";
import "../atoms/RadioGroup/RadioGroup.js";
import "../atoms/Typography/Typography.js";
import "../atoms/Stepper/Stepper.js";
import "../atoms/Step/Step.js";
import "../atoms/StepLabel/StepLabel.js";
import "../../style.module-3bbdaa05.js";
import "../../DateTimeSelectorMenu-62cb07f8.js";
import "moment-timezone";
import "../../runtime-6393d96e.js";
import "../../TableContext-59b96d2b.js";
import "../ReactTable/SearchFilter.js";
import "../ReactTable/TablePaginationActions.js";
import "../../styles/theme.js";
import "highcharts-react-official";
import "highcharts";
import "../molecules/Widgets/WidgetTimelineGraph/WidgetTimelineGraph.styles.js";
import "../../transform-17824b7f.js";
import "../molecules/NetworkMap/NetworkMapUtils.js";
import N from "../../assets/icons/AgentIcon.js";
import "../../ChatbotContext-74017a6b.js";
import { c as e } from "../../createSvgIcon-78894459.js";
const L = e(/* @__PURE__ */ r("path", {
  d: "M22 3.41 16.71 8.7 20 12h-8V4l3.29 3.29L20.59 2 22 3.41zM3.41 22l5.29-5.29L12 20v-8H4l3.29 3.29L2 20.59 3.41 22z"
}), "CloseFullscreen"), O = e(/* @__PURE__ */ r("path", {
  d: "M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
}), "MoreVert"), V = e(/* @__PURE__ */ r("path", {
  d: "M21 11V3h-8l3.29 3.29-10 10L3 13v8h8l-3.29-3.29 10-10z"
}), "OpenInFull"), k = e([/* @__PURE__ */ r("circle", {
  cx: "9",
  cy: "9",
  r: "4"
}, "0"), /* @__PURE__ */ r("path", {
  d: "M9 15c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm7.76-9.64-1.68 1.69c.84 1.18.84 2.71 0 3.89l1.68 1.69c2.02-2.02 2.02-5.07 0-7.27zM20.07 2l-1.63 1.63c2.77 3.02 2.77 7.56 0 10.74L20.07 16c3.9-3.89 3.91-9.95 0-14z"
}, "1")], "RecordVoiceOver"), b = e(/* @__PURE__ */ r("path", {
  d: "M12.99 9.18c0-.06.01-.12.01-.18 0-2.21-1.79-4-4-4-.06 0-.12.01-.18.01l4.17 4.17zm-6.1-3.56L4.27 3 3 4.27l2.62 2.62C5.23 7.5 5 8.22 5 9c0 2.21 1.79 4 4 4 .78 0 1.5-.23 2.11-.62L19.73 21 21 19.73l-8.62-8.62-5.49-5.49zM9 15c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm7.76-9.64-1.68 1.69c.84 1.18.84 2.71 0 3.89l1.68 1.69c2.02-2.02 2.02-5.07 0-7.27zM20.07 2l-1.63 1.63c2.77 3.02 2.77 7.56 0 10.74L20.07 16c3.9-3.89 3.91-9.95 0-14z"
}), "VoiceOverOff"), l2 = ({ subtitle: l, title: n, component: c, maximize: t, onSizeChange: o, onClose: m, actionMenu: s }) => {
  const { isProcessing: p, transcription: a } = M();
  function d() {
    a.toggleTranscription(), a.enabled && a.stopSpeaking();
  }
  return c || /* @__PURE__ */ i("div", { className: "chatbot-header", children: [
    /* @__PURE__ */ i("div", { className: "title", children: [
      /* @__PURE__ */ i("span", { className: "chatbot-header-title", children: [
        /* @__PURE__ */ r(N, { size: 28 }),
        " ",
        n
      ] }),
      l && /* @__PURE__ */ r("span", { className: "subtitle", children: l })
    ] }),
    /* @__PURE__ */ i("div", { className: "chatbot-actions", children: [
      p && /* @__PURE__ */ r(u, { size: 23 }),
      s && /* @__PURE__ */ r(C, { menu: s }),
      /* @__PURE__ */ r("div", { className: "action", onClick: d, children: a.enabled ? /* @__PURE__ */ r(k, {}) : /* @__PURE__ */ r(b, {}) }),
      /* @__PURE__ */ r("div", { className: "action", onClick: () => o == null ? void 0 : o(!t), children: t ? /* @__PURE__ */ r(V, {}) : /* @__PURE__ */ r(L, {}) }),
      /* @__PURE__ */ r("div", { className: "action", onClick: () => m == null ? void 0 : m(), children: "✖" })
    ] })
  ] });
};
function C({ menu: l }) {
  const [n, c] = z.useState(null);
  return /* @__PURE__ */ i(h, { children: [
    /* @__PURE__ */ r("div", { className: "action", onClick: (t) => c(t.currentTarget), children: /* @__PURE__ */ r(O, {}) }),
    /* @__PURE__ */ r(
      v,
      {
        anchorEl: n,
        open: !!n,
        onClose: () => c(null),
        children: l.map(
          (t, o) => t.hide ? null : /* @__PURE__ */ i(f, { onClick: () => {
            t.onClick(), c(null);
          }, children: [
            t.icon,
            "  ",
            t.title
          ] }, o)
        )
      }
    )
  ] });
}
export {
  l2 as default
};
