import { DataObject } from "./dataObject";

export interface EdgeWithPool extends Partial<Edge> {
  // Pool related fields will be added later on update
  edgeGroupId?: number;
  edgePoolId?: string;
  edgePoolname?: string;
  edgeRole?: string;
  emptyObj?: boolean;
}

export interface MutableEdge extends DataObject {
  name: string;
  enableSecurityGateway?: boolean;
  interfaceCount: number;
  dataNetworkPrivateIp?: string;
  data2NetworkPrivateIp?:string;
  radioNetworkPublicIp: string;
  location: Location;
  authZConfig?: AuthZConfig;
  networkMode: string;
  fabrics?: Fabrics;
  networks: Network[];
  managementNetwork?: ManagementNetwork;
  enableTrunkMode?:boolean;
  monitoringSystemConfig?: MonitoringSystemConfig;
  /** if carrier gatway */
  globalTcpMss?: number;
  edgeIpsecConfig? : EdgeIpsecConfig[];
  logLevelType: string;
}

export interface Edge extends MutableEdge {
  readonly acl?: EdgeAcl[] | null;
  readonly authKey?: string;
  readonly availableUpgrades?: AvailableUpgrade[];
  readonly availibilityZone: string;
  readonly caCert?: string;
  readonly controlPlaneInfo?: ControlPlaneInfo;
  readonly currentRelease?: string;
  readonly currentVersion?: string;
  readonly downloadOvfUrl?: string;
  readonly downloadUrl?: string;
  readonly installScriptUrl?: string;
  readonly instanceType?: string;
  readonly ipsecCert?: string;
  readonly ipsecKey?: string;
  readonly ipsecRightSourceIp?: string;
  readonly lteContext?: LteContext;
  readonly networkType?: string;
  readonly platformContext?: PlatformContext;
  readonly poolConfig?: PoolConfig;
  readonly proxyConfig?: ProxyConfig;
  readonly radioNetworkPrivateIp?: string;
  readonly reverseProxyContext?: ReverseProxyContext;
  readonly runtimeInfo?: RuntimeInfo;
  readonly serviceInstanceName?: string;
  readonly services: Service[] | null;
  readonly statusMessage?: string;
  readonly updatedTime?: number;
  readonly upgradeType?: string;
  readonly upgradedTime?: number;
  readonly userPlaneInfo?: UserPlaneInfo;
  readonly isRadioInterfacePhysical?: boolean;
  readonly n2Ip?: string;
  readonly amiId?: string;
  readonly dataNetworkPublicIp: string;
  readonly hostKey?: string;
  readonly publicDns?: string;
  readonly otp?: string;
  readonly AaaServer:AaaServer;
}

export interface Service {
  name: string;
}

export interface AaaServer {
  aaaServerProfileId?: string;
}

export interface AuthZConfig {
  aaaServerProfiles: AaaServer[];
  authzEnabled: boolean|undefined;
  isAuthzConfigChanged ?: boolean
}

export interface ReverseProxyContext {
  tr069ServerPort: number;
  tr069ServerUrl: string;
}

export interface PlatformContext {
  managementPrivateIp?: string;
  managementPrefixLength?: number;
  dataNetworkGateway: string;
  dataNetworkIpAllocationScheme: string;
  dataNetworkPrefixLength: number;
  dataNetworkPrivateIp: string;
  data2NetworkPrivateIp?:string;
  isNWExtensionMode?: boolean;
  managementVlanId?: number;
  radioNetworkIpAllocationScheme?: string;
  radioNetworkPrefixLength?: number;
  radioNetworkPrivateIp?: string;
  radioControlNetworkPrefixLength?: number;
  radioControlNetworkPrivateIp?: string;
  isRadioAndDataNetworkSame?: boolean;
}

export interface PoolConfig {
  edgeGroupId?: string;
  edgeCode: number;
}
export interface ProxyConfig {
  serverIp: string;
  serverPort?: number;
  enabled?: boolean;
}

export interface RuntimeInfo {
  checkinTime?: any;
  grpcClientGoodHealthLastTime?: number;
  grpcClientHealth?: string;
  health: string;
  supportChannel : {
    status : any;
  }
}

export interface DataNetwork {
  privateIp?: string;
  publicIp?: string;
  name?: string;
}

export interface RadioNetwork {
  privateIp?: string;
  publicIp?: string;
  name?: string;
}

export interface UserPlaneInfo {
  checkinTime?: any;
  connectedDevicesCount: number;
  connectedRadiosCount: number;
  dataNetworks: DataNetwork[];
  health: string;
  highAvailability: string;
  plmnId: string;
  radioNetworks: RadioNetwork[];
  role?: string;
}

export interface Network {
  dnnId: string;
  dns?: string[] | null;
  enableNat?: boolean;
  ipAllocationMode: string;
  subnet?: string;
  vlanId: number | null;
  gatewayIp?: string | null;
}

export interface Fabrics {
  fabricInterfaces: FabricInterfaces[];
}

export interface FabricInterfaces{
  fabricName: string;
  privateIp?: string;
  prefixLength?: number;
  networks: Network[];
}
export interface ManagementNetwork {
  gateway?: string;
  ip?: string;
  ipAllocationMode?: string;
  vlanId?: number;
  dns : string[];
}

export interface MonitoringSystemConfig {
  useDefault?: boolean;
  id?: string;
}

interface EdgeAcl {
  destinationId?: string;
  destinationIp?: string;
  destinationName?: string;
  port?: number;
  protocol?: string;
  sourceId?: string;
  sourceIp?: string;
  sourceName?: string;
}

export interface AvailableUpgrade {
  creationTime: Date | string;
  description?: string;
  edgeLocationType: string;
  ovaFileName?: string;
  id?: string;
  imageType: string;
  imageName?: string;
  release: string;
  runtimeInfo?: any;
  tags: string[];
  type?: string;
}

export interface ControlNetwork {
  privateIp?: string;
  publicIp?: string;
}

export interface ControlPlaneInfo {
  checkinTime?: number;
  connectedDevicesCount: number;
  connectedRadiosCount: number;
  controlNetworks: ControlNetwork[];
  health: string;
  highAvailability: string;
  plmnId: string;
  role?: string;
}

export interface Location {
  shortAddress: ""
  latitude: number;
  /**
   * @enum {string} GCP, AWS, PRIVATE CLOUD
   */
  locationType: string;
  longitude: number;
  name: string;
  tags?: any[] | null;
  enabled?: boolean;
  edgeEnabled?: boolean;
  id?: string;
  zoneInfo?: ZoneInfo;
  runtimeInfo?: any;
  type?: string;
  vmcEnabled?: boolean;
}

export interface ZoneInfo {
  groupName: string;
  regionName: string;
}

export interface LteContext {
  challengeKey: string;
  hardwareId: string;
}

export interface EdgeIpsecConfig {
  // name: string
  // rightId: string
  edgeIpsecCert: string
  edgeIpsecCertFileName: string
  edgeIpsecKey: string
  edgeIpsecKeyFileName: string
  // isDefault?: boolean
}

export const defaultEdge : Edge = {
  name : 'Edge-New',
  networkMode : "GATEWAY",
  instanceType: "EDGE",
  interfaceCount : 2,
  location : {
    latitude: 37.26534961208239,
    locationType: "PRIVATE CLOUD",
    longitude: -122.04076419999998,
    name: "Saratoga, CA, US",
    shortAddress: ""
  },
  availibilityZone : "Saratoga, CA, US",
  caCert : "",
  dataNetworkPublicIp : "",
  fabrics: {
    fabricInterfaces: []
  },
  networkType: "4G",
  networks : [
    {
      "dnnId": "Any Network",
      "dns": null,
      "enableNat": true,
      "ipAllocationMode": "IP_POOL",
      "subnet": "192.168.128.0/24",
      "vlanId": 0
  }
  ],
  radioNetworkPublicIp : "",
  services : [
    {
      name : "PRIVATE_CELLULAR"
    }
  ],
  logLevelType:"ERROR",
  globalTcpMss : 1200,
  AaaServer:{
    aaaServerProfileId:""
  },
  "status": "NOT_DEPLOYED",
  "type": "edge"
}